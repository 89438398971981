// FourthPart.jsx
import React, { Component } from 'react';
import styles from './FourthPart.module.css'; // Import the CSS module

class FourthPart extends Component {
  render() {
    return (
      <div className={styles.fullScreenContainer}>
        <div className={styles.item}>
          <p className={styles.title}>No matter the size of your platform,<br/><span className={styles.highlight}>we have a solution for you.</span></p>
          <button className={styles.button}>Browse Plans</button>
        </div>
        {/* <div className={styles.item}>
          <p className={styles.title}>Discover dedicated business solutions <span className={styles.highlight}>tailored to your needs.</span></p>
          <button className={styles.button}>Learn More</button>
        </div> */}
      </div>
    );
  }
}

export default FourthPart;

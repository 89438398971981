

import React, { useState, useEffect, useRef } from 'react';
// import Star from './Star';
import styles from './BackgroundWithStars.module.css';


const Star = ({ onMouseEnter, style }) => (
  <div
    className={styles.star}
    onMouseEnter={onMouseEnter}
    style={{
      ...style,
      transition: "left 0.2s ease, top 0.2s ease, opacity 0.5s ease",
      position: "absolute",
      width: "3px",
      height: "3px",
      borderRadius: "50%",
      backgroundColor: "white",
      opacity: 0.75, /* Ensure the star is fully visible initially */
    }}
  />
);

const generateStars = (amount) => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index,
    style: {
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
    },
  }));
};

const BackgroundWithStars = () => {
  const [stars, setStars] = useState(generateStars(1000));
  const containerRef = useRef(null);

  // const handleMouseMove = (event) => {
  //   const { clientX, clientY } = event;
  //   const { left, top, width, height } = containerRef.current.getBoundingClientRect();

  //   setStars((currentStars) =>
  //     currentStars.map((star) => {
  //       const starX = left + (parseFloat(star.style.left) / 100) * width;
  //       const starY = top + (parseFloat(star.style.top) / 100) * height;
  //       const distance = Math.sqrt(Math.pow(starX - clientX, 2) + Math.pow(starY - clientY, 2));

  //       if (distance < 100) { // Sensitivity radius in pixels
  //         const angle = Math.atan2(clientY - starY, clientX - starX);
  //         const moveDistance = 100 - distance; // Move more the closer the mouse is
  //         return {
  //           ...star,
  //           style: {
  //             ...star.style,
  //             left: `${Math.min(100, Math.max(0, (starX + Math.cos(angle) * moveDistance - left) / width * 100))}%`,
  //             top: `${Math.min(100, Math.max(0, (starY + Math.sin(angle) * moveDistance - top) / height * 100))}%`,
  //           },
  //         };
  //       }

  //       return star;
  //     })
  //   );
  // };

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const { left, top, width, height } = containerRef.current.getBoundingClientRect();
  
    setStars((currentStars) =>
      currentStars.map((star) => {
        const starX = left + (parseFloat(star.style.left) / 100) * width;
        const starY = top + (parseFloat(star.style.top) / 100) * height;
        const distance = Math.sqrt(Math.pow(starX - clientX, 2) + Math.pow(starY - clientY, 2));
  
        if (distance < 100) { // Sensitivity radius in pixels
          const angle = Math.atan2(clientY - starY, clientX - starX);
          const moveDistance = (100 - distance) * 2; // Increase this factor to make movement more sensitive
          return {
            ...star,
            style: {
              ...star.style,
              left: `${Math.min(100, Math.max(0, (starX + Math.cos(angle) * moveDistance - left) / width * 100))}%`,
              top: `${Math.min(100, Math.max(0, (starY + Math.sin(angle) * moveDistance - top) / height * 100))}%`,
            },
          };
        }
  
        return star;
      })
    );
  };

  
  return (
    <div ref={containerRef} className={styles.background} onMouseMove={handleMouseMove}>
      {stars.map((star) => (
        <Star key={star.id} style={star.style} />
      ))}
    </div>
  );
};

export default BackgroundWithStars;

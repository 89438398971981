// ThirdPart.jsx
import React, { Component } from 'react';
import styles from './ThirdPart.module.css'; // Import the CSS module

class ThirdPart extends Component {
  render() {
    return (
      <div id="work" className={styles.fullScreenContainer}>
        <div className={styles.contentContainer}>

          <div className={styles.item}>
            <div className={styles.textBlock}>
              <h3 className={styles.title}>Backup</h3>
              <p className={styles.psubtitle}>Seamlessly create backups of your social media content, including posts, photos, videos, comments, likes, and more.</p>
            </div>
            <div className={styles.imageBlock}>
              {/* Placeholder for image, use actual img tags with src in your project */}
              <img src="phone.PNG" className={styles.placeholderImg}/>
            </div>
          </div>

          <div className={`${styles.item}`}>
            <div className={styles.imageBlock}>
              {/* Placeholder for image, use actual img tags with src in your project */}
              <img src="shiled.PNG" className={styles.placeholderImg}/>
            </div>
            <div className={styles.textBlock}>
              <h3 className={styles.title}>Protection</h3>
              <p className={styles.psubtitle}>Rest easy knowing that your content and digital presence are safely stored, ready to be accessed whenever you need it.</p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.textBlock}>
              <h3 className={styles.title}>Recovery</h3>
              <p className={styles.psubtitle}>In case of an account suspension, ban, or hack, effortlessly restore your content to a new, ready to use account.</p>
            </div>
            <div className={styles.imageBlock}>
              {/* Placeholder for image, use actual img tags with src in your project */}
              <img src="ballon.PNG" className={styles.placeholderImg}/>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ThirdPart;

// FirstPart.jsx
import React, { Component } from 'react';
import styles from './FirstPart.module.css'; // Import the CSS module
import BackgroundWithStars from './BackgroundWithStars'; // Import the CSS module




class FirstPart extends Component {
  state={
    viewportWidth: window.innerWidth,
    widthToGoMobile : 1000,
  }


  handleResize = () => {
    // Update the state with the new viewport width
    this.setState({ viewportWidth: window.innerWidth });
  };

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  render() {
    
    const { viewportWidth } = this.state;

    return (
      <div className={styles.fullScreenContainer}>
         {viewportWidth >= this.state.widthToGoMobile ? <BackgroundWithStars/> : null}
        <h1 className={styles.mainTitle}>Safeguarding Your{viewportWidth >= this.state.widthToGoMobile ? <br/> : null} Social Media Content </h1>
        <p className={styles.subtitle}>Seamlessly back up your valuable content and ensure that your voice is never silenced. </p>
        {/* <p className={styles.subtitle}>Automatically Back Up  Your Social Media Content And Accounts Ensuring The Protection Of Your Digital Presence</p> */}

        

        <button onClick={()=> window.location.href="#pricing"} className={styles.gradientButton}>Start Free Trial Now</button>
 
        <div className={styles.backgroundImage} />
      </div>
    );
  }
}

export default FirstPart;

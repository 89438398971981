import React from 'react';
import WaitingList from './WaitingList'; // Assuming WaitingList is your class component
import { useParams } from 'react-router-dom';

function WaitingListWrapper() {
  const { plan } = useParams();
  return <WaitingList plan={plan} />;
}

export default WaitingListWrapper;

// // LandingPage.jsx
// import React, { Component } from 'react';
// import styles from './LandingPage.module.css';
// import FirstPart from './FirstPart'; 
// import SecondPart from './SecondPart'; 
// import ThirdPart from './ThirdPart'; 
// import FourthPart from './FourthPart'; 
// import FifthPart from './FifthPart'; 
// import SixthPart from './SixthPart'; 
// import FaqPart from './FaqPart'; 
// import PricingPart from './PricingPart'; 
// import Purplepart from './Purplepart'; 
// import FooterPart from './FooterPart'; 
// import NewUserContactUs from './NewUserContactUs'; 
// import axios from 'axios';
// import { withRouter } from 'react-router-dom';  // Ensure your project's version of react-router supports withRouter





// class LandingPage extends Component {




//   componentDidMount() {
//     const isinvite = window.location.href.includes('invite');
//     if(isinvite){
//     const { match } = this.props;
//     const Affiliate_uuid = match.params.Affiliate_uuid;
//     if (Affiliate_uuid) {
//       alert(Affiliate_uuid);
//       localStorage.setItem("Affiliate_uuid", Affiliate_uuid);

//       axios.post(`/sendIntiveAffiliate_uuid`, {
//         Affiliate_uuid: `${localStorage.getItem("Affiliate_uuid")}`
//       }).then((response) => {
//         console.log(response.data);
//         if (response.data.status == 0) {
//           console.log("Affiliate_uuid Done")
//         }
//       });
//     }
//   }
//   }

//   render() {


//     return (
//       <div className={styles.container}>
//        <FirstPart/>
//        <SecondPart/>
//        <ThirdPart/> 
//        <Purplepart/>
//        <FourthPart/>
//        <PricingPart/>
//        {/* <FifthPart/> testemonials are out for now*/} 
//        {/* <SixthPart/> join x number of creators */}
//        <FaqPart/>
//        <NewUserContactUs/>
//        <FooterPart/>
     
      
//       </div>
//     );
//   }
// }

// export default withRouter(LandingPage);

import React, { Component } from 'react';
import { useParams } from 'react-router-dom';  // Import useParams
import styles from './LandingPage.module.css';
import FirstPart from './FirstPart';
import SecondPart from './SecondPart';
import ThirdPart from './ThirdPart';
import FourthPart from './FourthPart';
import FifthPart from './FifthPart';
import SixthPart from './SixthPart';
import FaqPart from './FaqPart';
import PricingPart from './PricingPart';
import Purplepart from './Purplepart';
import FooterPart from './FooterPart';
import NewUserContactUs from './NewUserContactUs';
import axios from 'axios';

class LandingPage extends Component {
  componentDidMount() {
    const { Affiliate_uuid } = this.props;
    if (Affiliate_uuid) {
      
      localStorage.setItem("Affiliate_uuid", Affiliate_uuid);

      axios.post(`https://dashboard.backmysocials.com/sendIntiveAffiliate_uuid`, {
        Affiliate_uuid: `${localStorage.getItem("Affiliate_uuid")}`
      }).then((response) => {
        console.log(response.data);
        if (response.data.status === 0) {
          console.log("Affiliate_uuid Done")
        }
      }).catch(error => {
        console.error('Failed to send Affiliate_uuid:', error);
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <FirstPart/>
        <SecondPart/>
        <ThirdPart/>
        <Purplepart/>
        <FourthPart/>
        <PricingPart/>
        {/* <FifthPart/> testimonials are out for now */}
        {/* <SixthPart/> join x number of creators */}
        <FaqPart/>
        <NewUserContactUs/>
        <FooterPart/>
      </div>
    );
  }
}

// Functional component to use hooks
function LandingPageWithParams() {
  const params = useParams(); // Use the useParams hook to get route params
  return <LandingPage Affiliate_uuid={params.Affiliate_uuid} />;
}

export default LandingPageWithParams;

// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPageWithParams from './LandingPage'; // Adjust the path as necessary
import Navbar from './Navbar'; // Adjust the path as necessary
import WaitingListWrapper from './WaitingListWrapper'; // Adjust the path as necessary


import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div>
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPageWithParams />} />
          <Route path="/waitingList/:plan" element={<WaitingListWrapper />} />
          <Route path="/invite/:Affiliate_uuid" element={<LandingPageWithParams />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;

// SecondPart.jsx
import React from 'react';
import styles from './SecondPart.module.css'; // Import the CSS module

const Purplepart = () => {
  return (
    <div className={styles.fullScreenContainer2}>
      <div>
      <h2 className={styles.sectionTitle2}>But we don’t stop there.<br/></h2>
      <div className={styles.itemsContainer2}>
          <h3 className={styles.subtitle5}> Our analytics will get you back in touch with your biggest fans effortlessly.<br/> We dig deep to find your most loyal followers, helping you reconnect and rebuild, ensuring minimal disruption to your online presence.</h3>
      </div>
      </div>
    </div>
  );
};

export default Purplepart;

// Navbar.js
import React, { Component } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import styles from './Navbar.module.css'; // Import your CSS module

export default class CustomNavbar extends Component {

  state = {
    activeLink: 'about', // Start with 'home' as the default active link
  };

  setActiveLink = (activeLink) => {
    this.setState({ activeLink }, () => {
      document.querySelector("#NavbarToggle").click();
    });
  };


  render() {
    const { activeLink } = this.state;
    const isWaitingList = window.location.href.includes('waitingList');

    return (
      <Navbar fixed="top" expand="lg" className={styles.navbar}>
        <Container fluid>
          <Navbar.Brand href="/" className={`${styles.brand} ${styles.gradienttext}`}>
          BackMySocials
          </Navbar.Brand>
          <Navbar.Toggle id="NavbarToggle" className={styles.navbartoggler}  aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className={styles.navbarCollapse}>

         { isWaitingList ?
             <Nav className={styles.navCenter}>
             <Nav.Link href="https://backmysocials.com/" className={activeLink === 'about' ? `${styles.navLink} ${styles.active}` : styles.navLink}
               onClick={() => this.setActiveLink('about')}>About Us</Nav.Link>
             <Nav.Link href="https://backmysocials.com/"  className={activeLink === 'work' ? `${styles.navLink} ${styles.active}` : styles.navLink}
               onClick={() => this.setActiveLink('work')}>How It Works</Nav.Link>
             <Nav.Link href="https://backmysocials.com/" className={activeLink === 'pricing' ? `${styles.navLink} ${styles.active}` : styles.navLink}
               onClick={() => this.setActiveLink('pricing')}>Pricing</Nav.Link>
             <Nav.Link href="https://backmysocials.com/" className={activeLink === 'faq' ? `${styles.navLink} ${styles.active}` : styles.navLink}
               onClick={() => this.setActiveLink('faq')}>FAQ</Nav.Link>
           </Nav>
         
         : 

            <Nav className={styles.navCenter}>
              <Nav.Link href="#work" className={activeLink === 'about' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('about')}>About Us</Nav.Link>
              <Nav.Link href="#work"  className={activeLink === 'work' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('work')}>How It Works</Nav.Link>
              <Nav.Link href="#pricing" className={activeLink === 'pricing' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('pricing')}>Pricing</Nav.Link>
              <Nav.Link href="#faq" className={activeLink === 'faq' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('faq')}>FAQ</Nav.Link>
            </Nav>
                     }

            <Nav className={styles.navRight}>
              <Button onClick={() => this.setActiveLink('pricing')} href="#pricing" className={styles.startNowButton}>Start Now</Button>
              <Button  href="https://dashboard.backmysocials.com/NewUsercreateProfile?plan=plan"  className={styles.signInButton}>Sign In</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

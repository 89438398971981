// FooterPart.jsx
import React, { Component } from 'react';
import styles from './FooterPart.module.css'; // Import the CSS module

class FooterPart extends Component {
  render() {
    return (
        <footer style={{background:"#0000"}} className="page-footer font-small  pt-4">
        <div className="container-fluid text-center text-md-left">
            <div className="row">
                <div className="col-md-6 mt-md-0 mt-3">
                    <p style={{color :"#b2b2b2", fontSize:"20px"}}  className={`text-uppercase ${styles.gradienttext}`}>BackMySocials</p>
                    <p className={styles.linkcss}>Seamlessly back up your valuable content and ensure that your voice is never silenced.</p>
                </div>
    
                <hr className="clearfix w-100 d-md-none pb-0"/>
    
                <div className="col-md-6 mb-md-0 mb-6">
                    <p style={{color :"#b2b2b2", fontSize:"20px"}} className="text-uppercase"></p>
                    <ul className="list-unstyled">
                        {/* <li><a className={styles.linkcss} href="#work">How It Works</a></li>
                        <li><a className={styles.linkcss} href="#pricing">Pricing</a></li>
                        <li><a className={styles.linkcss} href="#faq">FAQ</a></li> */}
                        {/* <li><a className={styles.linkcss} href="#!">Terms</a></li> */}
                        <div style={{ textAlign: "center" }} >
                            <div style={{ fontSize: "12px", marginBottom:"6px" }} >Follow Us On</div>
            <a target='_blank' href='https://www.instagram.com/backmysocials'>
              <img src="/ig.png" width="25" height="25" />
            </a>
          </div>
                    </ul>
                </div>
    
                {/* <div className="col-md-3 mb-md-0 mb-3">
                <p style={{color :"#b2b2b2", fontSize:"20px"}} className="text-uppercase">Links</p>
                    <ul className="list-unstyled">
                        <li><a className={styles.linkcss} style={{color:"rgb(102, 102, 102)"}} href="#!">Link 1</a></li>
                        <li><a className={styles.linkcss} href="#!">Link 2</a></li>
                        <li><a className={styles.linkcss} href="#!">Link 3</a></li>
                        <li><a className={styles.linkcss} href="#!">Link 4</a></li>
                    </ul>
                </div> */}
            </div>
        </div>
    
        <div className={`${styles.linkcssfooter} footer-copyright text-center py-3`}>© 2024 Copyright: 
            <a className={styles.linkcss} href="https://BackMySocials.com/">BackMySocials.com</a>
        </div>
      </footer>
    );
  }
}

export default FooterPart;

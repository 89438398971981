// PricingPart.jsx
import React, { Component } from 'react';
import styles from './PricingPart.module.css'; // Import the CSS module

class PricingPart extends Component {

  state={
    Affiliate_uuid:"none"
  }
  componentDidMount() {
var Affiliate_uuid =  `${localStorage.getItem("Affiliate_uuid")}`
console.log("Affiliate_uuid" +Affiliate_uuid)
if(Affiliate_uuid){
  this.setState({ Affiliate_uuid : Affiliate_uuid})

}
  }






  render() {

    return (
      <div id="pricing" className={styles.fullScreenContainer}>
        <h2  className={styles.title}>Let's Kickstart Our Journey Together</h2>
        <h4 className={styles.subtitle}>Cancel anytime. Money back guarantee.</h4>
        <div className={styles.plansContainer}>
          {/* Plan 1 */}
          <div className={styles.plan}>
            <h3>Basic</h3>
            <p className={styles.price}>$19.99 / month</p>
            {/* <p>Billed annually or $17.45 billed monthly</p> */}
            <button  onClick={()=> window.location.href=`https://dashboard.backmysocials.com/NewUsercreateProfile?plan=Basic&Affiliate_uuid=${encodeURIComponent(this.state.Affiliate_uuid)}`}  className={styles.button}>Try For Free</button>
    
          <ul className={styles.featuresList}>
            <li>Weekly Backup</li>
            <li>Unlimited Media Content Backup</li>
            {/* <li>Unlimited Followers Backup</li>
            <li>Unlimited Following Backup</li> */}
            <li className={styles.unavailableFeature}>Unlimited Comments Backup</li>
            <li className={styles.unavailableFeature}>Unlimited Likes Backup</li>
            <li className={styles.unavailableFeature}>Smart Recovery Algorithm </li>
            <li className={styles.unavailableFeature}>Hate Speech and Bot Protection</li>
          </ul>
        
     
          </div>
          
          {/* Plan 2 */}
          <div className={styles.plan}>
            <h3>Pro</h3>
            <p className={styles.price}>$29.99 / month</p>
            {/* <p>Billed annually</p> */}
            <button onClick={()=> window.location.href=`https://dashboard.backmysocials.com/NewUsercreateProfile?plan=Pro&Affiliate_uuid=${encodeURIComponent(this.state.Affiliate_uuid)}`} className={styles.button}>Try For Free</button>
            <ul className={styles.featuresList}>
            <li>Daily Backup</li>
            <li>Unlimited Media Content Backup</li>
            {/* <li>Unlimited Followers Backup</li>
            <li>Unlimited Following Backup</li> */}
            <li>Unlimited Likes Backup</li>
            <li className={styles.unavailableFeature} >Unlimited Comments Backup</li>
            <li className={styles.unavailableFeature}>Smart Recovery Algorithm </li>
            <li className={styles.unavailableFeature}>Hate Speech and Bot Protection</li>
          </ul>
          </div>


   {/* Plan 3 */}
   <div className={styles.plan}>
            <h3>Best</h3>
            <p className={styles.price}>$49.99 / month</p>
            {/* <p>Billed annually</p> */}
            <button onClick={()=> window.location.href=`https://dashboard.backmysocials.com/NewUsercreateProfile?plan=Best&Affiliate_uuid=${encodeURIComponent(this.state.Affiliate_uuid)}`} className={styles.button}>Try For Free</button>
            <ul className={styles.featuresList}>
            <li>Daily Backup</li>
            <li>Unlimited Media Content Backup</li>
            {/* <li>Unlimited Followers Backup</li>
            <li>Unlimited Following Backup</li> */}
            <li>Unlimited Comments Backup</li>
            <li>Unlimited Likes Backup</li>
            <li>Smart Recovery Algorithm </li>
            <li>Hate Speech and Bots Protection</li>
          </ul>
          </div>
         
        </div>
      </div>
    );
  }
}

export default PricingPart;

// SecondPart.jsx
import React from 'react';
import styles from './SecondPart.module.css'; // Import the CSS module

const SecondPart = () => {
  return (
    <div className={styles.fullScreenContainer}>
      <h2 className={styles.sectionTitle}>Your social media accounts are more than just profiles. <br/> They’re your voice, brand, and community.<br/> We are here to help you safeguard this. </h2>
      {/* <div className={styles.itemsContainer}>
        <div className={styles.item}>
          <h3>Backup</h3>
          <p>Seamlessly create backups of your social media content, including posts, photos, videos, and more.</p>
          <button>Browse Plans</button>
        </div>
        <div className={styles.item}>
          <h3>Protection</h3>
          <p>Rest easy knowing that your content is safely stored, ready to be accessed whenever you need it.</p>
          <button>Browse Plans</button>
        </div>
        <div className={styles.item}>
          <h3>Recovery</h3>
          <p>In the event of an account suspension, ban, or hack, easily restore your content to a new account with just a few clicks.</p>
          <button>Browse Plans</button>
        </div>
      </div> */}
    </div>
  );
};

export default SecondPart;

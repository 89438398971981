
import React from 'react';
import axios from 'axios';
import styles from './Contactus.module.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'



class WaitingList extends React.Component {

  componentDidMount(){
    const { plan } = this.props;
    this.setState({ plan_type: plan });
  }



  state = {
    user_email: "",
    user_name: "",
    user_message: "",
    plan_type:""

  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };



  handleSubmit = (event) => {



    event.preventDefault();
    const data = new FormData(event.target);
    console.log(
      data.get("user_email") +
      data.get("user_name") +
      data.get("user_message")+
      data.get("plan_type")
      
    )

    var dataToSend = {
      user_email: data.get("user_email") || "",
      user_name: data.get("user_name") || "",
      user_message: data.get("user_message") || "",
      plan_type: this.state.plan_type || ""
    }

    axios.post(`/new_MessagesFromUsers_guest`, {
      dataToSend,

    })
      .then((response) => {
        if (response.data.status === 0) {
          alert("Message Sent Successfully")
          window.location.reload();
        } else {
          alert("Please try again")
          window.location.reload();
        }


      })
      .catch((err) => {
        alert("Error Please Try Again")
        window.location.reload();
      })


  }





  render() {

   
    return (
  
          <div className={styles.maindivfullscreen}>

            <div className={styles.WaitingListTitle}>
            {/* Due to high demand we have temporarily paused new sign-ups.
            <br/>
            You may join the waiting list by filling up the form below and we will contact you asap it is your turn. */}
<strong>We're thrilled to see so much excitement! </strong>  </div>

<div className={styles.WaitingListTitlesub}>
Right now, we're pausing new sign-ups to ensure everyone gets the best experience.<br/><br/>
Want in? Just fill out the form below to join our waiting list, and we'll let you know as soon as it's your turn to come aboard!
            </div>




            <div className={styles.maindiv1input}>

              <Form className={styles.form} onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className={styles.formlabel}>Your Email</Form.Label>
                  <Form.Control value={this.state.user_email}
                    onChange={this.handleChange} className={styles.forminput} name="user_email" type="text" placeholder="Enter Your Email" />


                  <Form.Label className={styles.formlabel}>Full Name</Form.Label>
                  <Form.Control value={this.state.user_name}
                    onChange={this.handleChange} className={styles.forminput} name="user_name" type="text" placeholder="Enter Your Name" />

                  <Form.Label className={styles.formlabel}>Message</Form.Label>
                  <Form.Control
                    className={styles.forminput} name="user_message" as="textarea" type="textarea" placeholder="Want to tell us something?" />

                
                 


                  <Form.Text bsPrefix={styles.buttondiv} className="text-muted">
                    <Button bsPrefix={styles.button} className={styles.button} type="submit">
                      Send
                    </Button>
                  </Form.Text>
                </Form.Group>
              </Form>

           

            </div>
          </div>





    );

  }




}







export default WaitingList;




// FaqPart.jsx
import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styles from './FaqPart.module.css'; // Import the CSS module

class FaqPart extends Component {
  render() {
    return (
      <div id="faq" className={styles.faqContainer}>
        <h2  className={styles.faqTitle}>Frequently Asked Questions</h2>
        <Accordion className={styles.accordion}  flush>
      <Accordion.Item className={styles.accordionitem} eventKey="0">
        <Accordion.Header className={styles.accordionheader}>What is BackMySocials?</Accordion.Header>
        <Accordion.Body className={styles.accordionbody}>
      
In today's online world, your social media isn't just a profile — it's your voice, your identity, and your community. But what if something goes wrong, like a hack or a ban? That's where BackMySocials steps in. We realize how vital it is to protect your social media presence. Our service offers a way to securely back up all your important content, giving you the peace of mind that your voice will always be heard, no matter what.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={styles.accordionitem} eventKey="1">
        <Accordion.Header className={styles.accordionheader}>What services does BackMySocials provide?</Accordion.Header>
        <Accordion.Body className={styles.accordionbody}>
        At BackMySocials, we offer a suite of features to keep your account secure and your content safe.<br/><br/>

<strong>Backup:</strong> Effortlessly back up your social media content, from posts and photos to videos and more.<br/><br/>
<strong>Protection:</strong> Sleep soundly knowing your content is securely stored and accessible whenever you need it.<br/><br/>
<strong>Recovery:</strong> Should you face an account suspension, ban, or hack, you can quickly restore your content to a new account with just a few clicks.<br/><br/>
<strong>Rebuild:</strong> We provide tools that help you regain your most dedicated followers on your new ready to use account. <br/><br/>

        </Accordion.Body>
      </Accordion.Item>
      

      {/* <Accordion.Item className={styles.accordionitem} eventKey="2">
        <Accordion.Header className={styles.accordionheader}>   How do the plans work and what do they cover?</Accordion.Header>
        <Accordion.Body className={styles.accordionbody}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item className={styles.accordionitem} eventKey="3">
        <Accordion.Header className={styles.accordionheader}>Why choose BackMySocials?</Accordion.Header>
        <Accordion.Body className={styles.accordionbody}>
        BackMySocials offers all users reliability, efficiency, and insight.<br/><br/>
        <strong>Reliability:</strong> Count on our secure and trustworthy platform to keep your digital content safe. <br/><br/>
        <strong>Efficiency:</strong> Our user-friendly backup and recovery process saves you both time and hassle. <br/><br/>
        <strong>Insight:</strong> Use our smart analytics to understand and expand your online community better. <br/><br/>
With BackMySocials, you’re not just protecting your content; you’re investing in a resilient and thriving online presence.
        </Accordion.Body>
      </Accordion.Item>
   
    </Accordion>
      </div>
    );
  }
}

export default FaqPart;
